import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEOWebpage from "../components/SEO/webpage"
import Logo from "../images/arcane.svg"

const IndexPage = () => (
  <Layout>
    <SEOWebpage
      title="Arcane Wraps | Vinyl Wraps Cincinnati"
      meta_description="Arcane Wraps, based in Cincinnati, Ohio, is a mobile contract company providing vinyl wraps and graphic installations for commercial and consumer customization."
      url="https://www.arcanewraps.com/"
    />
    <h1>Vinyl Wraps Cincinnati</h1>
    <hr />
    <p>Arcane Wraps, based in Cincinnati, Ohio, is a mobile contract company providing vinyl wraps and graphic installations for commercial and consumer customization. We offer an unique, high-quality install service capable of handling any of your installation needs. We have ample knowledge and many years of combined experience within the vinyl industry.</p>
    <br />
    <h3>Call Us Today</h3>
    <div className="main-button">
      <a href="tel:(513)620-5039">
        <strong>(513) 620-5039</strong>
      </a>
    </div>
    <br />
    <p>We specialize in vinyl installation of vehicles, trailers, walls, windows and more. Our installs can be completed on-site of companies or clients around the Ohio, Indiana, and Kentucky tri-state area. If you are interested in services, but are outside of this area, please feel free to reach out to us. We are fully insured and willing to come to you! By working with Arcane Wraps, you can rest assured that we strive for Quality; It’s not expensive, it’s PRICELESS.</p>
    <br />
    <img className="mainLogo" src={Logo} />
    <br />
    <br />
    <h3>Website Comming Soon</h3>
    <br />
  </Layout>
)

export default IndexPage
